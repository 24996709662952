import { useRef, useState } from "react";

import { NavLink } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";

import { observer } from "mobx-react";
import addressStore from "./addressStore";

function HeaderUserbox() {
  const [address, setAddress] = useState();
  const [instanceNew, setInstanceNew] = useState();
  const [buttonText, setButtonText] = useState("Connect your Neoline wallet");

  /* const handleAddressChange = (event) => {
    addressStore.setAddress(event.target.value);
  }; */

  async function getAddy() {
    // @ts-ignore
    // NEOLineN3 doesn't have getNetworks function
    // NEOLineN3 doesn't have getNetworks function

    //const instance2 = new NEOLine.Init();
    //const network = await instance2.getNetworks();
    let instance = new NEOLineN3.Init();
    const account = await instance.getAccount();
    const userAddress = account.address;
    console.log("this is account", account);

    console.log("this is userAddress", userAddress);

    setAddress(userAddress);
    setButtonText(userAddress);
    setInstanceNew(instance);
    console.log("this is instance", instance);

    //sets the address in mobx by calling the addressStore class
    //handleAddressChange(addressStore.address);
    addressStore.setAddress(userAddress);

    //const scriptHash = reverseHex(hash.substr(2, 40));

    //setScriptHash(scriptHash);

    //console.log("this is scriptHash", scriptHash);
  }

  const onClickGetAddy = function () {
    console.log("button is trggered");
    getAddy();
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <Grid item xs={12} align="center">
        <Box
          sx={{
            padding: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onClickGetAddy}
            /*   onClick={() => {
                    handleInputChange;
                  }} */
          >
            {buttonText}
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default HeaderUserbox;
