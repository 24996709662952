//this is the mint file linked to Header buttons!
import { useEffect, useState, Fragment } from "react";

import {
  Box,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Container,
  alpha,
  Button,
  LinearProgress,
  Typography,
  Link,
  Avatar,
  Grid,
  styled,
  ListItem,
  ListSubheader,
  useTheme,
  TextField,
  linearProgressClasses
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

import { wallet, rpc, sc, u } from "@cityofzion/neon-js";

//import base64url from "base64url";

function Leaderboard() {
  // const { t } = useTranslation();

  const [entrants, setEntrants] = useState();

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentMonthFullName = monthNames[currentMonthIndex];

  const monthNumber = 6;
  const monthName = monthNames[monthNumber - 1];
  const lastDayOfMonth = new Date(year, month, 0).getDate();

  console.log(`Current month: ${month}`);
  console.log(`Last day of the month: ${lastDayOfMonth}`);

  //const url = "http://127.0.0.1:50012"; //default.neo-express
  //const scriptHash = "0x2ab239b539f163ae8e8cb73c2a98d0ec47e54af6"; //NeoForecast
  //const NEOForecastScripthash = "0x2ab239b539f163ae8e8cb73c2a98d0ec47e54af6";
  //Local Net

  //const NEOForecastScripthash = "0x92acecc880952f2c7c278d7d2dbfecb7ba562dc4";
  //Test Net

  //const NEOForecastScripthash = "0x889a5338ae740d207dd84693770e49c436565c83";
  //Test Net rob.neoforecastcontract-original working
  const NEOForecastScripthash = "0x14bbb511907118b7969171b6913b36fe40c9a4db";
  //TestNet koka's 2nd contract
  //const NEOForecastScripthash = "0x4915c9f2afa864fed060f8f73452797310bacd2d"; //OracleExampleContract on testnet

  //const url = "http://seed1t4.neo.org:20332"; //testnet
  //const url = "http://127.0.0.1:50012"; //local Neo Express Net
  const url = "https://testnet1.neo.coz.io/";

  //const scriptHash = "0x0b7c630e4a6a3c0e15571a7e9a7ea9722a9c2ed4"; //assetCombiner(NEP17)-Testnet
  //The above combo works

  const rpcClient = new rpc.RPCClient(url);

  const param2 = sc.ContractParam.string("test");
  const args = [param2];

  const twoDimArray = [];

  async function getAllTokensFromNFTContract() {
    var result = await rpcClient.invokeFunction(
      NEOForecastScripthash,
      "getAllRecords",
      args
    );
    console.log("this is result!!!", result);
    var sessionId = result.session ?? "";
    var iteratorId = result.stack[0].id ?? "";
    console.log("this is iteratorId", iteratorId);
    var tokens2 = await rpcClient.traverseIterator(sessionId, iteratorId, 35);
    //this should be a loop to get all transactions

    console.log("this is tokens2", tokens2);
    return tokens2;
  }

  useEffect(() => {
    getAllTokensFromNFTContract()
      .then((value) => {
        value.forEach((element) => {
          console.log("---------------tokenID---------------");
          console.log("this is element", element);
          console.log("this is element", element.value);
          //element value returns the tokenId?
          var tokenId = element.value ?? "";
          console.log("this is tokenId", tokenId);
          twoDimArray.push(element);
        });
        console.log("this is twoDimArray", twoDimArray);
        //setArrayUnsorted(twoDimArray);
        return twoDimArray;
      })
      .then((twoDimArray) => {
        console.log("this is twoDimArray AGAIN", twoDimArray);

        //This function takes a two-dimensional array called twoDimArray and maps it to a new array called decodedArray.
        //For each object in twoDimArray, it extracts four values from the value property of the object and assigns them to variables.
        //It then decodes the senderAddress and prediction values using atob(), which is a function that decodes a base-64 encoded string.
        //The decodedMap variable is created by reducing the map.value array to an object. For each element in the array,
        //it decodes the key and value properties using atob() and adds them as key-value pairs to the object.
        //Finally, the function returns an object with an id property that is set to the current index plus one,
        //as well as the decoded senderAddress, prediction, and any additional key-value pairs from decodedMap.
        const decodedArray = twoDimArray
          .map((obj, index) => {
            const [senderAddress, prediction, integer, map] = obj.value;
            //The ordering of the destructuring refers to the order of the values in the object!
            console.log("this is obj.value", obj.value);
            console.log("this is senderAddress", senderAddress);
            console.log("this is senderAddress.value", senderAddress.value);
            console.log("this is senderAddress", senderAddress);
            //const decodedSenderAddress = atob(senderAddress.value);
            //console.log("this is decodedSenderAddress", decodedSenderAddress);
            console.log("this is prediction", prediction);
            console.log("this is prediction.value", prediction.value);
            let base64ScriptHash = prediction.value;
            const decodedPrediction = atob(prediction.value);
            console.log("this is decodedPrediction", decodedPrediction);

            let senderAddress2 = "j1+D1RnQ+bfAl9J2EbEwd69Pd+U=";

            function base64ToAddress(encodedString) {
              let hex = u.base642hex(encodedString);
              let address = wallet.getAddressFromScriptHash(u.reverseHex(hex));

              return address;
            }

            const decodedSenderAddress = base64ToAddress(senderAddress.value);

            console.log("dis be decodedSenderAddress", decodedSenderAddress);

            const decodedMap = map.value.reduce((acc, { key, value }) => {
              acc[atob(key.value)] = atob(value.value);
              console.log("this is acc", acc);
              return acc;
            }, {});
            return {
              id: index + 1,
              senderAddress: decodedSenderAddress,
              prediction: decodedPrediction,
              ...decodedMap
            };
          })
          .map(({ senderAddress, ...rest }) => ({ senderAddress, ...rest }));

        console.log("this is decodedArray", decodedArray);

        //add avatar to each object
        for (let i = 0; i < decodedArray.length; i++) {
          const avatarNum = i + 1;
          const avatarUrl = `/static/images/avatars/${avatarNum}.jpg`;
          decodedArray[i]["avatar"] = avatarUrl;
        }

        console.log("this is decodedArray", decodedArray);

        setEntrants(decodedArray);
      })
      .then(function () {
        console.log("this is entrants", entrants);
      });
  }, []);

  /*  async function getAllTokensFromNFTContract() {
    var result = await rpcClient.invokeFunction(scriptHash, "tokens");
    var sessionId = result.session ?? "";
    var iteratorId = result.stack[0].id ?? "";
    var tokens2 = await rpcClient.traverseIterator(sessionId, iteratorId, 5);

    return tokens2;
  } */
  //This is the old function which worked before subsequent discussion with KokaHunter!

  return (
    <Container maxWidth={"lg"}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: `${theme.colors.alpha.black[5]}`,
          textAlign: "center",
          p: 2
        }}
      >
        <Box sx={{ textAlign: "center", maxWidth: "100%" }}>
          {" "}
          {/* Ensure center alignment */}
          <Typography
            variant="caption"
            color="green"
            sx={{
              fontSize: `${theme.typography.pxToRem(18)}`
            }}
          >
            {currentMonthFullName ? (
              <>
                Predict the price as of {currentMonthFullName} {lastDayOfMonth}
              </>
            ) : (
              ""
            )}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              mt: 2
            }}
          >
            {"Leaderboard"}
          </Typography>
        </Box>
      </Box>

      {entrants
        ? entrants.map((item, index) => (
            <Fragment key={item.id}>
              {" "}
              {/* Corrected key to item.id */}
              <Grid
                container
                spacing={0}
                sx={{ mt: 2, display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} sm={8} md={6} lg={6}>
                  {" "}
                  {/* Adjust grid item sizes for responsiveness */}
                  <Button
                    variant="outlined"
                    sx={{
                      justifyContent: "space-between",
                      display: "flex",
                      py: 2,
                      px: 1.5,
                      [theme.breakpoints.down("sm")]: {
                        px: 0 // decrease padding/margin for larger screens
                      },
                      width: "100%", // Ensure full width for responsiveness
                      flexDirection: { xs: "row" } // Stack elements vertically on small screens
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        flexGrow: 1 // Allow this box to grow as needed
                      }}
                    >
                      <Typography
                        variant={isSmallScreen ? "h5" : "h3"}
                        sx={{
                          mr: 3,
                          [theme.breakpoints.down("lg")]: {
                            mr: 2 // decrease padding/margin for larger screens
                          },
                          [theme.breakpoints.down("sm")]: {
                            mr: 1, // decrease padding/margin for larger screens
                            ml: 2
                          },
                          fontWeight: "bold"
                        }}
                      >
                        {index + 1}
                      </Typography>
                      <Avatar
                        sx={{
                          width: 42,
                          height: 42
                        }}
                        alt={item.name}
                        src={item.avatar}
                      />
                      {/* Name and Address */}
                      <Box sx={{ flexDirection: "column", flexGrow: 1 }}>
                        <Typography
                          color="text.primary"
                          variant={isSmallScreen ? "body2" : "h3"}
                          fontWeight="bold"
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          noWrap
                          variant={isSmallScreen ? "body2" : "h4"}
                        >
                          {item.senderAddress}
                        </Typography>
                      </Box>
                    </Box>
                    {/* Forecast and View Button */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-end", // Align to the right
                        flexGrow: 1 // Allow this box to grow as needed
                      }}
                    >
                      <Typography color="green" variant="h3" sx={{ mr: 2 }}>
                        ${item.forecast}
                      </Typography>
                      <Button size="small" variant="text" color="primary">
                        {"View"}
                      </Button>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          ))
        : ""}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Container>
  );
}

export default Leaderboard;
