import { Helmet } from "react-helmet-async";
import PageHeader from "./PageHeader";
import PageTitleWrapper from "./PageTitleWrapper";
//Note - moved PageTitleWrapper here from the content folder because hard to track down when deploying!!!
import { Grid, LinearProgress } from "@mui/material";
import Footer from "./Footer";
//Note - moved Footer here from the content folder because hard to track down when deploying!!!

import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ForecastCard from "./charts/ForecastCard";

import TextField from "@mui/material/TextField";

import { FormControl } from "@mui/material";

import { useForm, Controller, useFieldArray } from "react-hook-form";

import Box from "@mui/material/Box";

import Neon, { sc } from "@cityofzion/neon-js";

import { observer, Provider } from "mobx-react";

import addressStore from "../../../layouts/SidebarLayout/Header/Userbox/addressStore.js";

//import { setter } from "../../../../packages/neo/wallet/setter.ts";

// Define an observable object for your application

const InputPage2 = observer(() => {
  const [address, setAddress] = useState("");
  const [forecast, setForecast] = useState();
  const [nickname, setNickname] = useState();
  const [scriptHash, setScriptHash] = useState();
  const [instanceNew, setInstanceNew] = useState();
  const [formValue, setFormValue] = useState("");
  const { handleSubmit, reset, control } = useForm();
  const [localAddress, setLocalAddress] = useState("");

  useEffect(async () => {
    setAddress(addressStore.getAddress());
    if (addressStore.getAddress()) {
      let instance = new NEOLineN3.Init();
      const account = await instance.getAccount();
      const userAddress = account.address;
      console.log("this is account", account);

      console.log("this is userAddress", userAddress);

      setAddress(userAddress);
      setInstanceNew(instance);
      console.log("this is instance", instance);
    } else {
      console.log("please sign in!");
    }
  }, [addressStore.getAddress()]);

  const onError = (errors, e) => console.log(errors, e);

  const onSubmit = async function (data) {
    Promise.resolve()

      .then(() => {
        console.log("this is instanceNew2", instanceNew);

        instanceNew.AddressToScriptHash({ address: address }).then((result) => {
          const { scriptHash } = result;
          let scriptHashFinal = "0x" + scriptHash;

          console.log("this is wallet scriptHash", scriptHashFinal);
          setScriptHash(scriptHashFinal);
        });
      })
      .then(() => {
        let forecast = data.forecast;
        let name = data.nickname;
        let string = JSON.stringify({ name: name, forecast: forecast });
        let encodedString = btoa(string);
        console.log("this is encodedString", encodedString);

        let season = "test";
        let tokenKey = "WGxMKzVINXJCd1ZsOTBOeVJvemNhWjNva1FjPQ==";

        let attributes = encodedString;
        let stringEncodedText = JSON.stringify({
          season: season,
          tokenKey: tokenKey,
          attributes: attributes
        });
        let stringEncodedTextB = btoa(stringEncodedText);
        console.log("this is stringEncodedTextB", stringEncodedTextB);

        console.log("this is da address", address);
        return stringEncodedTextB;
      })
      .then((stringEncodedTextB) => {
        return instanceNew.invoke({
          scriptHash: "0xd2a4cff31913016155e38e474a2c06d08be276cf",
          operation: "transfer",
          args: [
            {
              type: "Address",
              value: address
            },
            /* {
              type: "Address",
              value: "NXtYKZGhxDC3M33wsUQRzBrMNFXGBmC6jL",
            }, */ //This is the working version of koka's original on testnet
            /* {
              type: "Address",
              value: "NfwLaJt498CjXn11WdEHgQagkE7vp29BoZ" //This is the working version of koka's original 2nd on testnet
            }, */
            {
              type: "Address",
              value: "NfwLaJt498CjXn11WdEHgQagkE7vp29BoZ" //This is the working version of koka's original 2nd on testnet
            },
            {
              type: "Integer",
              value: "100000000"
            },
            {
              type: "String",
              value: stringEncodedTextB
            }
          ],
          fee: "0.0001",
          broadcastOverride: false,
          signers: [
            {
              //account: "0xb83c34adfaa1b364c79939142046b508d545256f",
              //The account is the scripthash of my wallet address!
              account: "0xd2a4cff31913016155e38e474a2c06d08be276cf",

              //scopes: 16
              scopes: 1
              /* allowedContracts: ["0x6123f30d275130ac59a6e1a76b36775ab2b4a0c4", "0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5"],
                    allowedGroups: [] */
            }
          ]
        });
      })
      .then((result) => {
        console.log("this is da final scriptHash", scriptHash);
        console.log("Invoke transaction successful!");
        console.log("Transaction ID: " + result.txid);
        console.log("RPC node URL: " + result.nodeURL);
      })
      .catch((error) => {
        const { type, description, data } = error;
        switch (type) {
          case "NO_PROVIDER":
            console.log("No provider available.");
            break;
          case "RPC_ERROR":
            console.log(
              "There was an error when broadcasting this transaction to the network."
            );
            break;
          case "CANCELED":
            console.log("The user has canceled this transaction.");
            break;
          default:
            // Not an expected error object.  Just write the error to the console.
            console.error(error);
            break;
        }
      });
    console.log("this is data submitted", data);
  };

  /////////////////////////////////////////////////Edit this below!!!

  /* const onSubmit = async (values, actions) => {
    handleSubmit(values);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  }; */

  return (
    <>
      <Helmet>
        <title>Crypto Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid
          sx={{
            px: 4
            //padding-left, padding-right
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          columns={12}
        >
          <Grid item md={3} xs={12}>
            {address}

            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image="/static/images/avatars/forecast_4.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {addressStore.addressStore}
                </Typography>

                <Typography gutterBottom variant="h5" component="div">
                  Enter your name/nickname/handle
                </Typography>
                {/*   <Box component="form" noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Box> */}
                <Controller
                  control={control}
                  name="nickname"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      size="small"
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      variant="outlined"
                      style={{ width: "200px", margin: "5px" }}
                      type="text"
                      label="nickname"
                    />
                  )}
                />
                <Typography gutterBottom variant="h5" component="div">
                  Enter your month-end forecast here
                </Typography>
                {/*   <Box component="form" noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Outlined"
                    variant="outlined"
                  />
                </Box> */}
                <Controller
                  control={control}
                  name="forecast"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      size="small"
                      error={!!error}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      variant="outlined"
                      style={{ width: "200px", margin: "5px" }}
                      type="text"
                      label="setgoal"
                    />
                  )}
                />
              </CardContent>
              <Grid item xs={12} align="center">
                <Box
                  sx={{
                    padding: 1
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"

                    /*   onClick={() => {
                    handleInputChange;
                  }} */
                  >
                    Submit
                  </Button>
                  <setter myprop={forecast} />
                </Box>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </form>

      <Footer />
    </>
  );
});

export default InputPage2;
