import { useRoutes } from "react-router-dom";
import router from "./router";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";

function App() {
  const content = useRoutes(router);

  /*  const tagManagerArgs = {
    gtmId: "GTM-HC5H4F79MJ",
  };

  TagManager.initialize(tagManagerArgs); */

  //Google Analytics

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
