//stores address and makes it global in mobx!

import { makeAutoObservable } from "mobx";

class AddressStore {
  address = "";

  constructor() {
    makeAutoObservable(this);
  }

  setAddress(address) {
    this.address = address;
  }

  getAddress() {
    return this.address;
  }
}

const addressStore = new AddressStore();

export default addressStore;
