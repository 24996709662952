//this is the mint file linked to Header buttons!
import { useEffect, useState, Fragment } from "react";

import {
  Box,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Card,
  alpha,
  Button,
  LinearProgress,
  Typography,
  Link,
  Avatar,
  styled,
  ListItem,
  useTheme,
  linearProgressClasses
} from "@mui/material";

//import { useTranslation } from "react-i18next";

import { wallet, rpc, sc, u } from "@cityofzion/neon-js";

//import base64url from "base64url";

import crypto from "crypto";

import Neon from "@cityofzion/neon-js";

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.secondary.main};
    color: ${theme.palette.getContrastText(theme.colors.secondary.main)};
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(0.5, 1.5, 0.3)};
  `
);

const LinearProgressPrimary = styled(LinearProgress)(
  ({ theme }) => `
        height: 8px;
        border-radius: ${theme.general.borderRadiusLg};

        &.${linearProgressClasses.colorPrimary} {
            background-color: ${alpha(theme.colors.primary.main, 0.1)};
        }
        
        & .${linearProgressClasses.bar} {
            border-radius: ${theme.general.borderRadiusLg};
            background-color: ${theme.colors.primary.main};
        }
    `
);

function PreviousWinners() {
  // const { t } = useTranslation();

  const [entrants, setEntrants] = useState();

  const theme = useTheme();

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const monthNumber = 6;
  const monthName = monthNames[monthNumber - 1];
  const lastDayOfMonth = new Date(year, month, 0).getDate();

  console.log(`Current month: ${month}`);
  console.log(`Last day of the month: ${lastDayOfMonth}`);

  //const url = "http://127.0.0.1:50012"; //default.neo-express
  //const scriptHash = "0x2ab239b539f163ae8e8cb73c2a98d0ec47e54af6"; //NeoForecast
  //const NEOForecastScripthash = "0x2ab239b539f163ae8e8cb73c2a98d0ec47e54af6";
  //Local Net

  //const NEOForecastScripthash = "0x92acecc880952f2c7c278d7d2dbfecb7ba562dc4";
  //Test Net

  const NEOForecastScripthash = "0x889a5338ae740d207dd84693770e49c436565c83";
  //Test Net rob.neoforecastcontract

  const url = "http://seed1t4.neo.org:20332"; //testnet
  //const url = "http://127.0.0.1:50012"; //local Neo Express Net

  //const scriptHash = "0x0b7c630e4a6a3c0e15571a7e9a7ea9722a9c2ed4"; //assetCombiner(NEP17)-Testnet
  //The above combo works

  const rpcClient = new rpc.RPCClient(url);

  const param2 = sc.ContractParam.string("test");
  const args = [param2];

  const twoDimArray = [];

  async function getAllTokensFromNFTContract() {
    var result = await rpcClient.invokeFunction(
      NEOForecastScripthash,
      "getAllRecords",
      args
    );
    var sessionId = result.session ?? "";
    var iteratorId = result.stack[0].id ?? "";
    var tokens2 = await rpcClient.traverseIterator(sessionId, iteratorId, 8);

    return tokens2;
  }

  useEffect(() => {
    getAllTokensFromNFTContract()
      .then((value) => {
        value.forEach((element) => {
          console.log("---------------tokenID---------------");
          console.log(element.value);
          console.log("this is element", element.value);
          //element value returns the tokenId?
          var tokenId = element.value ?? "";
          console.log("this is tokenId", tokenId);
          twoDimArray.push(element);
        });
        console.log("this is twoDimArray", twoDimArray);
        //setArrayUnsorted(twoDimArray);
        return twoDimArray;
      })
      .then((twoDimArray) => {
        console.log("this is twoDimArray AGAIN", twoDimArray);

        //This function takes a two-dimensional array called twoDimArray and maps it to a new array called decodedArray.
        //For each object in twoDimArray, it extracts four values from the value property of the object and assigns them to variables.
        //It then decodes the senderAddress and prediction values using atob(), which is a function that decodes a base-64 encoded string.
        //The decodedMap variable is created by reducing the map.value array to an object. For each element in the array,
        //it decodes the key and value properties using atob() and adds them as key-value pairs to the object.
        //Finally, the function returns an object with an id property that is set to the current index plus one,
        //as well as the decoded senderAddress, prediction, and any additional key-value pairs from decodedMap.
        const decodedArray = twoDimArray
          .map((obj, index) => {
            const [senderAddress, prediction, integer, map] = obj.value;
            //The ordering of the destructuring refers to the order of the values in the object!
            console.log("this is obj.value", obj.value);
            console.log("this is senderAddress", senderAddress);
            console.log("this is senderAddress.value", senderAddress.value);
            console.log("this is senderAddress", senderAddress);
            //const decodedSenderAddress = atob(senderAddress.value);
            //console.log("this is decodedSenderAddress", decodedSenderAddress);
            console.log("this is prediction", prediction);
            console.log("this is prediction.value", prediction.value);
            let base64ScriptHash = prediction.value;
            const decodedPrediction = atob(prediction.value);
            console.log("this is decodedPrediction", decodedPrediction);

            let senderAddress2 = "j1+D1RnQ+bfAl9J2EbEwd69Pd+U=";

            function base64ToAddress(encodedString) {
              let hex = u.base642hex(encodedString);
              let address = wallet.getAddressFromScriptHash(u.reverseHex(hex));

              return address;
            }

            const decodedSenderAddress = base64ToAddress(senderAddress.value);

            console.log("dis be decodedSenderAddress", decodedSenderAddress);

            /* let hex = Neon.u.base642hex(sender);
            let address = Neon.wallet.getAddressFromScriptHash(
              Neon.u.reverseHex(hex)
            );

            console.log(`The address is ${address}.`); */

            //below is from ftw-client
            /*  const base64ToAddress = function (base64ScriptHash) {
              wallet.getAddressFromScriptHash(
                base64ToHash160(base64ScriptHash)
              );
            };

            const base64ToHash160 = function (base64ScriptHash) {
              u.reverseHex(u.base642hex(base64ScriptHash));
            }; */

            //This is from chatGPT! Same error as above
            /*    const scriptHash = Neon.u.HexString.fromBase64(base64ScriptHash);
            const addressTwo = Neon.wallet.getAddressFromScriptHash(
              scriptHash,
              Neon.wallet.NEO3_MAGIC_NUMBER
            );

            console.log("this is addressTwo", addressTwo); */

            const decodedMap = map.value.reduce((acc, { key, value }) => {
              acc[atob(key.value)] = atob(value.value);
              console.log("this is acc", acc);
              return acc;
            }, {});
            return {
              id: index + 1,
              senderAddress: decodedSenderAddress,
              prediction: decodedPrediction,
              ...decodedMap
            };
          })
          .map(({ senderAddress, ...rest }) => ({ senderAddress, ...rest }));

        console.log("this is decodedArray", decodedArray);

        //add avatar to each object
        for (let i = 0; i < decodedArray.length; i++) {
          const avatarNum = i + 1;
          const avatarUrl = `/static/images/avatars/${avatarNum}.jpg`;
          decodedArray[i]["avatar"] = avatarUrl;
        }

        console.log("this is decodedArray", decodedArray);

        setEntrants(decodedArray);
      })
      .then(function () {
        console.log("this is entrants", entrants);
      });
  }, []);

  /*  async function getAllTokensFromNFTContract() {
    var result = await rpcClient.invokeFunction(scriptHash, "tokens");
    var sessionId = result.session ?? "";
    var iteratorId = result.stack[0].id ?? "";
    var tokens2 = await rpcClient.traverseIterator(sessionId, iteratorId, 5);

    return tokens2;
  } */
  //This is the old function which worked before subsequent discussion with KokaHunter!

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `${theme.colors.alpha.black[5]}`,
        textAlign: "center",
        p: 2
      }}
    >
      <Box sx={{ textAlign: "center", maxWidth: "100%" }}>
        {" "}
        {/* Ensure center alignment */}
        <Typography
          variant="caption"
          color="green"
          sx={{
            fontSize: `${theme.typography.pxToRem(18)}`
          }}
        >
          Stay Tuned
        </Typography>
      </Box>
    </Box>
  );
}

export default PreviousWinners;
