import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Stack, Box, useTheme, Divider } from "@mui/material";
import { CardActionArea } from "@mui/material";
import Image from "mui-image";
import neoLogo from "./neo-logo.jpg";

import { useEffect, useState } from "react";

function ForecastCard({
  valueABC,
  tabFinal,
  seasonalValueChange,
  NEOprice,
  periodMain,
  theNEOPrice,
  //Note- because these values would be updated prior to clicking the 'generate forecast button in adjuster2', if your result card is kept open, it will update when this
  //value is changed. Therefore, need to figure out a way to hide result card after this value is updated in seasonality tab.
  valuesPrice,
}) {
  const [pred, setPred] = useState([""]);
  const [tab, setTab] = useState([""]);
  const [seasonalChange, setSeasonalChange] = useState([""]);
  const [finalValue, setFinalValue] = useState([""]);
  const [lastDay, setLastDay] = useState("");

  const [formattedBTCPrice, setFormattedBTCPrice] = useState([""]);
  const [formattedETHPrice, setFormattedETHPrice] = useState([""]);
  const [finalPeriodDisplay, setFinalPeriodDisplay] = useState([""]);

  const theme = useTheme();

  useEffect(() => {
    (function () {
      console.log("this is valueABC", valueABC);

      var today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      )
        .toISOString()
        .slice(0, 10);

      setLastDay(lastDayOfMonth);

      let pred = valueABC[0] + valueABC[1] + valueABC[2];
      setPred(pred);
      setTab(tabFinal);
      setSeasonalChange(seasonalValueChange);

      let adjustedChangeA =
        valueABC[0] * 0.55 + valueABC[1] * 0.3 + valueABC[2] * 0.15;
      let adjustedChangeB =
        valueABC[0] * 0.55 + valueABC[2] * 0.3 + valueABC[4] * 0.15;
      let adjustedChangeC =
        valueABC[2] * 0.3 + valueABC[3] * 0.55 + valueABC[4] * 0.15;

      console.log("this is adjustedChangeA", adjustedChangeA);

      console.log("this is adjustedChangeB", adjustedChangeB);

      console.log("this is adjustedChangeC", adjustedChangeC);

      console.log("this is tabFinal", tabFinal);

      console.log("this is seasonalValueChange", seasonalValueChange);

      console.log("this is NEOprice", NEOprice);

      console.log("this is theNEOPrice", theNEOPrice);

      console.log("this is seasonalChange", seasonalChange);

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      const formatterPrice = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      let formula;

      if (tabFinal === "1") {
        formula =
          12.974 * theNEOPrice ** -0.714 * (adjustedChangeA * theNEOPrice) +
          theNEOPrice +
          seasonalValueChange;
        console.log("this is formula", formula);
        let formattedFormula = formatter.format(formula);
        setFinalValue(formattedFormula);
      }
      //12.974 formula derived from drawing a curved line in Excel to get the formula. Logic is the effect of any trend/resid is doubled at
      //this low price stage. Effect diminishes to 1-1 as price rises. Also, going to let seasonalValue to just rock to see what happens!
      if (tabFinal === "2") {
        formula =
          12.974 * theNEOPrice ** -0.714 * (adjustedChangeB * theNEOPrice) +
          theNEOPrice +
          seasonalValueChange;
        console.log("this is formula", formula);
        let formattedFormula = formatter.format(formula);
        setFinalValue(formattedFormula);
      }
      if (tabFinal === "3") {
        formula =
          12.974 * theNEOPrice ** -0.714 * (adjustedChangeC * theNEOPrice) +
          theNEOPrice +
          seasonalValueChange;
        console.log("this is formula", formula);
        let formattedFormula = formatter.format(formula);
        setFinalValue(formattedFormula);
      }

      setFormattedBTCPrice(formatterPrice.format(valuesPrice[0]));
      //This is necessary to properly display BTC price in card as selected value
      setFormattedETHPrice(formatterPrice.format(valuesPrice[3]));
      //This is necessary to properly display ETH price in card as selected value

      setFinalPeriodDisplay(periodMain);
      //this is necessary to show final period here from Seasonality tab ONLY after 'generate' button has been clicked

      /*       console.log('this is seasonalValueChange', seasonalValueChange);
       */
    })();
  }, [valueABC /* seasonalValueChange, */ /* periodMain */ /* finalValue   */]);
  //If the forecast card is exposed; changing any of these values should cause the price to update as well.
  //Only want to update on final value!

  //if (tab === '1')
  //adding 'valueABC' into [] causes this effect to update whenever state changes
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia>
          <Box>
            <Image src={neoLogo} alt="neoLogo" /* height={140} */ />
          </Box>
        </CardMedia>

        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              mt: 2.5,
              textAlign: "center",
            }}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            Month-end Forecast Value
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              mt: 2.5,
              textAlign: "center",
            }}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            //spacing={2}
          >
            The forecasted value of NEO at <br></br>
            <b>{lastDay}</b> is:
          </Typography>
          <br></br>
          <Typography
            variant="h3"
            fontWeight="bold"
            textAlign="center"
            marginLeft={1}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              pb: 2,
              color: `${theme.colors.success.main}`,
              //display: 'inline'
            }}
          >
            {" "}
            {finalValue}
          </Typography>
          <Divider></Divider>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mt: 2.5,
              textAlign: "center",
            }}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            //spacing={2}
          >
            <b>
              <i>Selected values</i>
            </b>
          </Typography>

          {tab === "1" ? (
            <>
              <Stack
                sx={{
                  mt: 2.5,
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="subtitle2">
                  Seasonal Period <br></br>
                  <Typography variant="h4" color="text.primary">
                    {" "}
                    <b> {finalPeriodDisplay}</b>{" "}
                  </Typography>
                  days
                </Typography>
                <Typography variant="subtitle2">
                  Basket <br></br>{" "}
                  <Typography variant="h4" color="text.primary">
                    <b>A</b>{" "}
                  </Typography>
                </Typography>
              </Stack>
              <br></br>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                {" "}
                <b>
                  {" "}
                  <i>Selected month-end prices:</i>
                </b>
              </Typography>
              <Stack
                sx={{
                  mt: 2.5,
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {" BTCUSD"}
                  </Typography>
                  <Typography variant="h4">{formattedBTCPrice}</Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"Crude Oil Futures"}
                  </Typography>
                  <Typography variant="h4">${valuesPrice[1]}</Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"MCHI"}
                  </Typography>
                  <Typography variant="h4">${valuesPrice[2]}</Typography>
                </Box>
              </Stack>
            </>
          ) : (
            ""
          )}
          {tab === "2" ? (
            <>
              <Stack
                sx={{
                  mt: 2.5,
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="subtitle2">
                  Seasonal Period <br></br>
                  <Typography variant="h4" color="text.primary">
                    {" "}
                    <b> {finalPeriodDisplay}</b>{" "}
                  </Typography>
                  days
                </Typography>
                <Typography variant="subtitle2">
                  Basket <br></br>{" "}
                  <Typography variant="h4" color="text.primary">
                    <b>B</b>{" "}
                  </Typography>
                </Typography>
              </Stack>
              <br></br>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                {" "}
                <b>
                  {" "}
                  <i>Selected month-end prices:</i>
                </b>
              </Typography>
              <Stack
                sx={{
                  mt: 2.5,
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {" BTCUSD"}
                  </Typography>
                  <Typography variant="h4">{formattedBTCPrice}</Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"Corn Futures"}
                  </Typography>
                  <Typography variant="h4">${valuesPrice[4]}</Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"MCHI"}
                  </Typography>
                  <Typography variant="h4">${valuesPrice[2]}</Typography>
                </Box>
              </Stack>
            </>
          ) : (
            ""
          )}
          {tab === "3" ? (
            <>
              <Stack
                sx={{
                  mt: 2.5,
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="subtitle2">
                  Seasonal Period <br></br>
                  <Typography variant="h4" color="text.primary">
                    {" "}
                    <b> {finalPeriodDisplay}</b>{" "}
                  </Typography>
                  days
                </Typography>
                <Typography variant="subtitle2">
                  Basket <br></br>{" "}
                  <Typography variant="h4" color="text.primary">
                    <b>C</b>{" "}
                  </Typography>
                </Typography>
              </Stack>
              <br></br>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                {" "}
                <b>
                  {" "}
                  <i>Selected month-end prices:</i>
                </b>
              </Typography>
              <Stack
                sx={{
                  mt: 2.5,
                  textAlign: "center",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"ETHUSD"}
                  </Typography>
                  <Typography variant="h4">{formattedETHPrice}</Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"Corn Futures"}
                  </Typography>
                  <Typography variant="h4">${valuesPrice[4]}</Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    {"MCHI"}
                  </Typography>
                  <Typography variant="h4">${valuesPrice[2]}</Typography>
                </Box>
              </Stack>
            </>
          ) : (
            ""
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ForecastCard;
