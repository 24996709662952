import { Typography, Avatar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function PageHeader({ NEOprice }) {
  const user = {
    name: 'Clement',
    avatar: '/static/images/avatars/ezekiel.jpg'
  };
  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            mr: 2,
            width: theme.spacing(8),
            height: theme.spacing(8)
          }}
          variant="rounded"
          alt={user.name}
          src={user.avatar}
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Welcome, {user.name}!
        </Typography>
        <Typography variant="subtitle2">
          Today is a good day to start forecasting NEO prices!
        </Typography>
        <Grid item md={6} xs={12}>
          {NEOprice ? (
            <Typography variant="subtitle2">NEO price: {NEOprice}</Typography>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
