import { Helmet } from "react-helmet-async";
import PageHeader from "./PageHeader";
import PageTitleWrapper from "./PageTitleWrapper";
//Note - moved PageTitleWrapper here from the content folder because hard to track down when deploying!!!
import { styled } from "@mui/material/styles";

//Note - moved Footer here from the content folder because hard to track down when deploying!!!

import { useState, useEffect } from "react";

import {
  Accordion,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Card,
  CardMedia,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from "@mui/material";

function About() {
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>

      <Container maxWidth={"lg"}>
        <Grid
          container
          spacing={0}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e6e6e6",
              borderRadius: 3
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                order: { xs: 2, sm: 2, md: 2, lg: 2 }
              }}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  ml: 2,
                  mb: 2
                }}
              >
                <List>
                  <ListItem>
                    <Typography variant="h5" color="green">
                      <CheckCircle />
                    </Typography>
                    <ListItemText
                      variant="h5"
                      primary="Make a forecast on what the NEO price will be at end of month (15:00 UTC)"
                    />
                  </ListItem>
                  <ListItem>
                    <Typography color="green">
                      <CheckCircle />
                    </Typography>
                    <ListItemText primary="Cost is just one gas" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                order: { xs: 1, sm: 1, md: 1, lg: 1 }
              }}
            >
              <Box sx={{ mt: 2, mb: 2 }}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    component="img"
                    alt="ezekiel"
                    height="400"
                    image="/static/images/avatars/ezekiel_1.png"
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#FFE0B2",
              borderRadius: 3
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              order={{ md: 2, lg: 1 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box sx={{ mt: 2, mb: 2 }}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    component="img"
                    alt="ezekiel"
                    height="400"
                    image="/static/images/avatars/clement_1.png"
                  />
                </Card>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  width: "80%",
                  ml: 2,
                  mr: 2,
                  mb: 2
                }}
              >
                <Box
                  sx={{
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80%",
                    ml: 2,
                    mb: 2
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", color: "green" }}
                  >
                    Winner take all- Smart contract automatically pays out all
                    proceeds received at month-end to winner (whoever's guess is
                    closest, less mandatory system fees)
                  </Typography>
                  <br></br>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", color: "green" }}
                  >
                    Your prediction is stored on the blockchain in an NFT; You
                    can always refer to the NFT in the future to prove to others
                    how good of a forecaster/ analyst you are!
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Grid
            container
            spacing={0}
            sx={{ mt: 3, mb: 4, backgroundColor: "#dae1eb", borderRadius: 3 }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              order={{ md: 2, lg: 1 }}
              display="flex"
              alignItems="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                order: { xs: 2, sm: 2, md: 2, lg: 2 }
              }}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  width: "80%",
                  ml: 2,
                  mb: 2
                }}
              >
                <Box
                  sx={{
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80%",
                    ml: 2,
                    mb: 2
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", color: "green" }}
                  >
                    Before making guesses, consider trying out our custom
                    forecast dashboard, or create your own algorithm. Feel free
                    to share your predictions with others
                  </Typography>
                  <br></br>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", color: "green" }}
                  >
                    All data on the Leaderboard and Previous Winners pages are
                    pulled from the NEO blockchain using RPCClient
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                order: { xs: 1, sm: 1, md: 1, lg: 1 }
              }}
            >
              <Box sx={{ mt: 2, mb: 2 }}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    component="img"
                    alt="ezekiel"
                    height="400"
                    image="/static/images/avatars/ezekiel_2.png"
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default About;
