import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { AppCContextProvider } from "./common/hooks/use-app";

import "nprogress/nprogress.css";
import App from "./App";
import { SidebarProvider } from ".//contexts/SidebarContext";
//import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <AppCContextProvider>
          <App />
        </AppCContextProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

//serviceWorker.unregister();
