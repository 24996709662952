import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "./layouts/SidebarLayout/index.js";
//import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "./components/SuspenseLoader/index.js";

import Leaderboard from "./content/dashboards/Crypto/Leaderboard.js";
import Testing from "./content/dashboards/Crypto/Testing.js";
import Rules from "./content/dashboards/Crypto/Rules.js";
import About from "./content/dashboards/Crypto/About.js";

import PreviousWinners from "./content/dashboards/Crypto/PreviousWinners.js";

import InputPage2 from "./content/dashboards/Crypto/InputPage2";

import Wallet from "./layouts/SidebarLayout/Header/Buttons/wallet.js";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import("./content/overview/index.js")));

// Dashboards

const Crypto = Loader(
  lazy(() => import("./content/dashboards/Crypto/index.js"))
);

// Applications

/* const Messenger = Loader(
  lazy(() => import("./content/applications/Messenger/index.js"))
); */
/* const Transactions = Loader(
  lazy(() => import("./content/applications/Transactions/index.js"))
); */
/* const UserProfile = Loader(
  lazy(() => import("./content/applications/Users/profile/index.js"))
);
const UserSettings = Loader(
  lazy(() => import("./content/applications/Users/settings/index.js"))
); */

// Components

/* const Buttons = Loader(
  lazy(() => import("./content/pages/Components/Buttons/index.tsx"))
);
const Modals = Loader(
  lazy(() => import("./content/pages/Components/Modals/index.tsx"))
);
const Accordions = Loader(
  lazy(() => import("./content/pages/Components/Accordions/index.js"))
);
const Tabs = Loader(
  lazy(() => import("./content/pages/Components/Tabs/index.tsx"))
);
const Badges = Loader(
  lazy(() => import("./content/pages/Components/Badges/index.tsx"))
);
const Tooltips = Loader(
  lazy(() => import("./content/pages/Components/Tooltips/index.tsx"))
);
const Avatars = Loader(
  lazy(() => import("./content/pages/Components/Avatars/index.tsx"))
);
const Cards = Loader(
  lazy(() => import("./content/pages/Components/Cards/index.tsx"))
);
const Forms = Loader(
  lazy(() => import("./content/pages/Components/Forms/index.tsx"))
);
 */
// Status

/* const Status404 = Loader(
  lazy(() => import("./content/pages/Status/Status404/index.tsx"))
);
const Status500 = Loader(
  lazy(() => import("./content/pages/Status/Status500/index.tsx"))
);
const StatusComingSoon = Loader(
  lazy(() => import("./content/pages/Status/ComingSoon/index.tsx"))
);
const StatusMaintenance = Loader(
  lazy(() => import("./content/pages/Status/Maintenance/index.tsx"))
);
 */
const routes = [
  {
    path: "",
    /*     element: <BaseLayout />,*/
    // Note: <BaseLayout> was the default layout; it modifies the Overview page; it has no sidebar!
    element: <SidebarLayout />,
    children: [
      {
        path: "/",
        element: <Crypto />
      },
      //Note- any children to <Crypto> should replace those below!
      {
        path: "overview",
        element: <Navigate to="/" replace />
      },
      {
        path: "wallet",
        element: <Wallet />
      }
      /*  {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
          {
            path: "500",
            element: <Status500 />,
          },
          {
            path: "maintenance",
            element: <StatusMaintenance />,
          },
          {
            path: "coming-soon",
            element: <StatusComingSoon />,
          },
        ],
      }, */
      /*   {
        path: "*",
        element: <Status404 />,
      }, */
    ]
  },
  {
    path: "dashboards",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="crypto" replace />
      },
      {
        path: "crypto",
        element: <Crypto />
      }

      /* {
        path: "messenger",
        element: <Messenger />,
      }, */
    ]
  },
  {
    path: "leaderboard",
    element: <SidebarLayout />,
    children: [
      {
        path: "/leaderboard",
        element: <Leaderboard />
      }

      /* {
        path: "messenger",
        element: <Messenger />,
      }, */
    ]
  },
  {
    path: "rules",
    element: <SidebarLayout />,
    children: [
      {
        path: "/rules",
        element: <Rules />
      }
    ]
  },
  {
    path: "about",
    element: <SidebarLayout />,
    children: [
      {
        path: "/about",
        element: <About />
      }
    ]
  },
  {
    path: "previouswinners",
    element: <SidebarLayout />,
    children: [
      {
        path: "/previouswinners",
        element: <PreviousWinners />
      }

      /* {
        path: "messenger",
        element: <Messenger />,
      }, */
    ]
  },

  {
    path: "inputPage2",
    element: <SidebarLayout />,
    children: [
      {
        path: "/inputPage2",
        element: <InputPage2 />
      }

      /* {
        path: "messenger",
        element: <Messenger />,
      }, */
    ]
  }
  /*  {
    path: "management",
    element: <SidebarLayout />,
    children: [
       {
        path: "",
        element: <Navigate to="transactions" replace />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      }, 
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Navigate to="details" replace />,
          },
          {
            path: "details",
            element: <UserProfile />,
          },
          {
            path: "settings",
            element: <UserSettings />,
          },
        ],
      },
    ],
  }, */
  /*  {
    path: "/components",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="buttons" replace />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "modals",
        element: <Modals />,
      },
      {
        path: "accordions",
        element: <Accordions />,
      },
      {
        path: "tabs",
        element: <Tabs />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "forms",
        element: <Forms />,
      },
    ],
  }, */
];

export default routes;
